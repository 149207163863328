<template>
  <div>
    <quote-list></quote-list>
  </div>
</template>

<script>
import QuoteList from './QuoteList.vue';

export default {
  components: {
    QuoteList,
  },

  data() {
    return {};
  },
};
</script>
